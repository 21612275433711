<!-- 课时记录by id -->
<template>
  <div class="mentor_history_byid">
    <HistoryList :history="history" :filter="{}" @refreshData="refreshData"></HistoryList>
  </div>
</template>
<script>
import HistoryList from './HistoryList.vue'
import {getHistoryList} from '../../../api/learner'

export default {
  name: 'mentor-history-byid',
  data() {
    return {
      history: []
    }
  },
  methods: { 
    refreshData(list) {
      Array.prototype.concat.call(this.history, list)
    },
    async initData() {
      const {id} = this.$route.query
      if (!id) {
        this.$toast('没有找到对应信息, 即将跳转')
        setTimeout(() => {
          this.$router.go(-1)
        }, 3000)
      }
      const {list, total} = await getHistoryList({
        groupId: +id
      })
      this.history = list
      this.total = total
    }
  },
  created () {
    this.initData()
  },
  components: {HistoryList}
}
</script>
<style lang="stylus" scoped>
@import "../../../common.styl"
@import "../../../theme.styl"
.mentor_history
  padding-top 79px
  .list
    padding 10px

</style>
<style lang="stylus">
.mentor_history_byid
  button
    border-radius 4px !important
</style>